import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Flag from 'react-world-flags';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import UndoIcon from '@mui/icons-material/Undo';
import { RenderValueProfitabilityRank, RenderInvestmentRank, RenderMomentumRank } from './Common';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { URL_BACKEND } from './Constantes';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import LanguagePicker from './LanguagePicker';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import ReactGA from 'react-ga4';
import {GOOGLE_ANALYTICS} from './Constantes';

function Stock(props) {
    ReactGA.initialize(GOOGLE_ANALYTICS);

    const { t, i18n } = useTranslation();
    let { ticker } = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if(props.forceLanguage==="fr"){
            i18n.changeLanguage("fr");
        }
    }, [props.forceLanguage, i18n]);

    useEffect(() => {
        document.title = `${t('titleStock')} ${ticker} | ${t('title')}`;
        document.documentElement.lang=i18n.language;
        document.getElementsByTagName('meta')["description"].content = t("seo_about_us_page_title");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname});
    }, [t,ticker,i18n]);

    // Remarque : le tableau vide de dépendances [] indique
    // que useEffect ne s’exécutera qu’une fois, un peu comme
    // componentDidMount()
    useEffect(() => {
        const url = `${URL_BACKEND}/stock/${ticker}`;
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                // Remarque : il faut gérer les erreurs ici plutôt que dans
                // un bloc catch() afin que nous n’avalions pas les exceptions
                // dues à de véritables bugs dans les composants.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [ticker])

    if (error) {
        return <div>Error : {error.message}</div>;
    } else if (!isLoaded || !items) {
        return <div>Loading...</div>;
    } else {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CssBaseline />
                <Grid alignContent={'center'} justify="center" width="95%" maxWidth="975px" >
                    <Box style={{ textAlign: 'right' }}>
                        <LanguagePicker />
                        <ButtonGroup aria-label="home" style={{ "paddingLeft": "2em" }}>
                            <Button startIcon={<UndoIcon />} variant="outlined" onClick={() => navigate(-1)}> {t('previous')}</Button>
                        </ButtonGroup>
                    </Box>
                    <Typography variant="h3" component="div" gutterBottom>
                        {t('titleStock')} {items.shortName.name} (<Flag code={items.flag} style={{ width: 50 }} /> {ticker})
                    </Typography>
                    <Typography variant="h4" component="div" gutterBottom>
                        {t('basicInfo')} {items.date != null ? ` (${t('asof')} ${items.date})` : ''}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {items.peapme ? <><CheckCircleIcon /> {t('compliant')}</> : <><CancelIcon /> {t('notcompliant')}</>} {t('peapme')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {t('marketcap')} : {items.normalizedMarketCap != null ? Math.round(items.normalizedMarketCap / 1000000) : <DoNotDisturbIcon />} M€
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {t('volume')} : {items.normalizedVolume != null ? Math.round(items.normalizedVolume / 1000) : <DoNotDisturbIcon />} k€
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {t('sector')} : {items.sector != null ? items.sector : <DoNotDisturbIcon />}
                    </Typography>
                    <Typography variant="h4" component="div" gutterBottom>
                        Valuation ratios
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Price-to-book : {items.electedPriceToBook != null ? items.electedPriceToBook : <DoNotDisturbIcon />} &rarr; Score : {items.valueRank != null ? <RenderValueProfitabilityRank value={items.valueRank} /> : <DoNotDisturbIcon />}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Price-to-earnings : {items.priceToEarningsTTM != null ? items.priceToEarningsTTM : <DoNotDisturbIcon />} {items.priceToEarningsTTMDate != null ? ` (${t('asof')} ${items.priceToEarningsTTMDate})` : ''} &rarr; Score : {items.valuePERank != null ? <RenderValueProfitabilityRank value={items.valuePERank} /> : <DoNotDisturbIcon />}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Price-to-sales : {items.priceToSalesTTM != null ? items.priceToSalesTTM : <DoNotDisturbIcon />} {items.priceToSalesTTMDate != null ? ` (${t('asof')} ${items.priceToSalesTTMDate})` : ''} &rarr; Score : {items.valuePSRank != null ? <RenderValueProfitabilityRank value={items.valuePSRank} /> : <DoNotDisturbIcon />}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Price-earnings-to-Growth : {items.priceEarningsToGrowthTTM != null ? items.priceEarningsToGrowthTTM : <DoNotDisturbIcon />} {items.priceEarningsToGrowthTTMDate != null ? ` (${t('asof')} ${items.priceEarningsToGrowthTTMDate})` : ''} &rarr; Score : {items.valuePEGRank != null ? <RenderValueProfitabilityRank value={items.valuePEGRank} /> : <DoNotDisturbIcon />}
                    </Typography>
                    <Typography variant="h4" component="div" gutterBottom>
                        Profitability
                    </Typography>
                    <BlockMath>{String.raw`\text{Gross profitability} = \frac{\text{gross profit}}{\text{total asset}} = \frac{${items.grossProfit != null ? items.grossProfit : '?'}_\text{${items.grossProfitDate != null ? items.grossProfitDate : ''}}}{${items.totalAssets != null ? items.totalAssets : '?'}_\text{${items.totalAssetsDate != null ? items.totalAssetsDate : ''}}} = ${items.grossProfitability != null ? items.grossProfitability : '?'}`}</BlockMath>

                    <Typography variant="body1" gutterBottom>
                        &rarr; Score : {items.grossProfitabilityRank != null ? <RenderValueProfitabilityRank value={items.grossProfitabilityRank} /> : <DoNotDisturbIcon />}
                    </Typography>
                    <BlockMath>{String.raw`\text{Operating profitability} = \frac{\text{gross profit - sgaa - interest expense}}{\text{stockholders equity + minority interest}}`}</BlockMath>
                    <BlockMath>{String.raw`= \frac{${items.grossProfit != null ? items.grossProfit : '?'}_\text{${items.grossProfitDate != null ? items.grossProfitDate : ''}}-${items.sellingGeneralAndAdministration != null ? items.sellingGeneralAndAdministration : '?'}_\text{${items.sellingGeneralAndAdministrationDate != null ? items.sellingGeneralAndAdministrationDate : ''}}-${items.interestExpense != null ? items.interestExpense : 0}_\text{${items.interestExpenseDate != null ? items.interestExpenseDate : ''}}}{${items.stockholdersEquity != null ? items.stockholdersEquity : '?'}_\text{${items.stockholdersEquityDate != null ? items.stockholdersEquityDate : ''}}+${items.minorityInterest != null ? items.minorityInterest : 0}_\text{${items.minorityInterestDate != null ? items.minorityInterestDate : ''}}} = ${items.operatingProfitability != null ? items.operatingProfitability : '?'}`}</BlockMath>

                    <Typography variant="body1" gutterBottom>
                        &rarr; Score : {items.operatingProfitabilityRank != null ? <RenderValueProfitabilityRank value={items.operatingProfitabilityRank} /> : <DoNotDisturbIcon />}
                    </Typography>
                    <Typography variant="h4" component="div" gutterBottom>
                        Investment
                    </Typography>
                    <BlockMath>{String.raw`\text{Asset growth} = \frac{\text{total asset annual}}{\text{total asset the year before}} = \frac{${items.totalAssetsAnnual != null ? items.totalAssetsAnnual : '?'}_\text{${items.totalAssetsAnnualDate != null ? items.totalAssetsAnnualDate : ''}}}{${items.totalAssetsAnnualYearBefore != null ? items.totalAssetsAnnualYearBefore : '?'}_\text{${items.totalAssetsAnnualYearBeforeDate != null ? items.totalAssetsAnnualYearBeforeDate : ''}}} = ${items.investment ? items.investment : '?'}`}</BlockMath>

                    <Typography variant="body1" gutterBottom>
                        &rarr; Score : {items.investmentRank != null ? <RenderInvestmentRank value={items.investmentRank} /> : <DoNotDisturbIcon />}
                    </Typography>
                    <Typography variant="h4" component="div" gutterBottom>
                        Momentum
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {t('let')} <InlineMath math="p_i" /> {t('betheprice')} <InlineMath math="i" /> {t('weeksago')}.
                    </Typography>
                    <BlockMath>{String.raw`\text{Momentum} = \frac{p_4}{p_{4+3 \times 4}} + \frac{p_4}{p_{4+6 \times 4}} + \frac{p_4}{p_{4+9 \times 4}} + \frac{p_4}{p_{4+12 \times 4}}`}</BlockMath>
                    <BlockMath>{String.raw`= \frac{${items.oneMonthAgo != null ? items.oneMonthAgo : '?'}_\text{${items.oneMonthAgoDate != null ? items.oneMonthAgoDate : ''}}}{${items.fourMonthAgo != null ? items.fourMonthAgo : '?'}_\text{${items.fourMonthAgoDate != null ? items.fourMonthAgoDate : ''}}} + \frac{${items.oneMonthAgo != null ? items.oneMonthAgo : '?'}_\text{${items.oneMonthAgoDate != null ? items.oneMonthAgoDate : ''}}}{${items.sevenMonthAgo != null ? items.sevenMonthAgo : '?'}_\text{${items.sevenMonthAgoDate != null ? items.sevenMonthAgoDate : ''}}} + \frac{${items.oneMonthAgo != null ? items.oneMonthAgo : '?'}_\text{${items.oneMonthAgoDate != null ? items.oneMonthAgoDate : ''}}}{${items.tenMonthAgo != null ? items.tenMonthAgo : '?'}_\text{${items.tenMonthAgoDate != null ? items.tenMonthAgoDate : ''}}} + \frac{${items.oneMonthAgo != null ? items.oneMonthAgo : '?'}_\text{${items.oneMonthAgoDate != null ? items.oneMonthAgoDate : ''}}}{${items.thirteenMonthAgo != null ? items.thirteenMonthAgo : '?'}_\text{${items.thirteenMonthAgoDate != null ? items.thirteenMonthAgoDate : ''}}}`}</BlockMath>
                    <BlockMath>{String.raw`= 4 \times ${items.momentumScore != null ? items.momentumScore : '?'}`}</BlockMath>
                    <Typography variant="body1" gutterBottom>
                        &rarr; Score : {items.momentumRank != null ? <RenderMomentumRank value={items.momentumRank} /> : <DoNotDisturbIcon />}
                    </Typography>

                    <Typography variant="h4" component="div" gutterBottom>
                        Ken French Operating Profitability
                    </Typography>
                    {(items.stockholdersEquity != null || items.commonStockEquity != null) && (<><Typography variant="h5" component="div" gutterBottom>
                        Taxes
                    </Typography>
                    <BlockMath>{String.raw`\text{DeferredTaxes} = \text{CurrentDeferredTaxesAssets} + \text{NonCurrentDeferredTaxesAssets} - \text{CurrentDeferredTaxesLiabilities} - \text{NonCurrentDeferredTaxesLiabilities}`}</BlockMath>
                    <BlockMath>{String.raw` = ${items.currentDeferredTaxesAssets != null ? items.currentDeferredTaxesAssets : 0}_\text{${items.currentDeferredTaxesAssetsDate != null ? items.currentDeferredTaxesAssetsDate : ''}} + ${items.nonCurrentDeferredTaxesAssets != null ? items.nonCurrentDeferredTaxesAssets : 0}_\text{${items.nonCurrentDeferredTaxesAssetsDate != null ? items.nonCurrentDeferredTaxesAssetsDate : ''}} - ${items.currentDeferredTaxesLiabilities != null ? items.currentDeferredTaxesLiabilities : 0}_\text{${items.currentDeferredTaxesLiabilitiesDate != null ? items.currentDeferredTaxesLiabilitiesDate : ''}} - ${items.nonCurrentDeferredTaxesLiabilities != null ? items.nonCurrentDeferredTaxesLiabilities : 0}_\text{${items.nonCurrentDeferredTaxesLiabilitiesDate != null ? items.nonCurrentDeferredTaxesLiabilitiesDate : ''}}`}</BlockMath>
                    </>)}
                    <Typography variant="h5" component="div" gutterBottom>
                        Book Equity
                    </Typography>
                    {items.stockholdersEquity != null && (<><BlockMath>{String.raw`\text{BookEquity} = \text{StockholdersEquity} + \text{DeferredTaxes} - \text{PreferredStock}`}</BlockMath>
                        <BlockMath>{String.raw` = ${items.stockholdersEquity != null ? items.stockholdersEquity : '?'}_\text{${items.stockholdersEquityDate != null ? items.stockholdersEquityDate : ''}} + ${items.currentDeferredTaxesAssets != null ? items.currentDeferredTaxesAssets : 0}_\text{${items.currentDeferredTaxesAssetsDate != null ? items.currentDeferredTaxesAssetsDate : ''}} + ${items.nonCurrentDeferredTaxesAssets != null ? items.nonCurrentDeferredTaxesAssets : 0}_\text{${items.nonCurrentDeferredTaxesAssetsDate != null ? items.nonCurrentDeferredTaxesAssetsDate : ''}} - ${items.currentDeferredTaxesLiabilities != null ? items.currentDeferredTaxesLiabilities : 0}_\text{${items.currentDeferredTaxesLiabilitiesDate != null ? items.currentDeferredTaxesLiabilitiesDate : ''}} - ${items.nonCurrentDeferredTaxesLiabilities != null ? items.nonCurrentDeferredTaxesLiabilities : 0}_\text{${items.nonCurrentDeferredTaxesLiabilitiesDate != null ? items.nonCurrentDeferredTaxesLiabilitiesDate : ''}} - ${items.preferredStock != null ? items.preferredStock : 0}_\text{${items.preferredStockDate != null ? items.preferredStockDate : ''}}`}</BlockMath></>)}
                    {items.stockholdersEquity == null && items.commonStockEquity != null && (<><BlockMath>{String.raw`\text{BookEquity} = \text{CommonStockEquity} + \text{DeferredTaxes}`}</BlockMath>
                        <BlockMath>{String.raw` = ${items.commonStockEquity != null ? items.commonStockEquity : '?'}_\text{${items.commonStockEquityDate != null ? items.commonStockEquityDate : ''}} + ${items.currentDeferredTaxesAssets != null ? items.currentDeferredTaxesAssets : 0}_\text{${items.currentDeferredTaxesAssetsDate != null ? items.currentDeferredTaxesAssetsDate : ''}} + ${items.nonCurrentDeferredTaxesAssets != null ? items.nonCurrentDeferredTaxesAssets : 0}_\text{${items.nonCurrentDeferredTaxesAssetsDate != null ? items.nonCurrentDeferredTaxesAssetsDate : ''}} - ${items.currentDeferredTaxesLiabilities != null ? items.currentDeferredTaxesLiabilities : 0}_\text{${items.currentDeferredTaxesLiabilitiesDate != null ? items.currentDeferredTaxesLiabilitiesDate : ''}} - ${items.nonCurrentDeferredTaxesLiabilities != null ? items.nonCurrentDeferredTaxesLiabilities : 0}_\text{${items.nonCurrentDeferredTaxesLiabilitiesDate != null ? items.nonCurrentDeferredTaxesLiabilitiesDate : ''}}`}</BlockMath></>)}
                    {items.stockholdersEquity == null && items.commonStockEquity == null && (<><BlockMath>{String.raw`\text{BookEquity} = \text{TotalAssets} + \text{DeferredTaxes} - \text{TotalLiabilitiesNetMinorityInterest}`}</BlockMath>
                    <BlockMath>{String.raw` = ${items.totalAssets != null ? items.totalAssets : '?'}_\text{${items.totalAssetsDate != null ? items.totalAssetsDate : ''}} + ${items.currentDeferredTaxesAssets != null ? items.currentDeferredTaxesAssets : 0}_\text{${items.currentDeferredTaxesAssetsDate != null ? items.currentDeferredTaxesAssetsDate : ''}} + ${items.nonCurrentDeferredTaxesAssets != null ? items.nonCurrentDeferredTaxesAssets : 0}_\text{${items.nonCurrentDeferredTaxesAssetsDate != null ? items.nonCurrentDeferredTaxesAssetsDate : ''}} - ${items.currentDeferredTaxesLiabilities != null ? items.currentDeferredTaxesLiabilities : 0}_\text{${items.currentDeferredTaxesLiabilitiesDate != null ? items.currentDeferredTaxesLiabilitiesDate : ''}} - ${items.nonCurrentDeferredTaxesLiabilities != null ? items.nonCurrentDeferredTaxesLiabilities : 0}_\text{${items.nonCurrentDeferredTaxesLiabilitiesDate != null ? items.nonCurrentDeferredTaxesLiabilitiesDate : ''}} - ${items.totalLiabilitiesNetMinorityInterest != null ? items.totalLiabilitiesNetMinorityInterest : '?'}_\text{${items.totalLiabilitiesNetMinorityInterestDate != null ? items.totalLiabilitiesNetMinorityInterestDate : ''}}`}</BlockMath>
                    </>)}
                    <BlockMath>{String.raw` = ${items.bookEquity != null ? items.bookEquity : '?'}`}</BlockMath>
                    <Typography variant="h5" component="div" gutterBottom>
                        Profitability
                    </Typography>
                    <BlockMath>{String.raw`\text{Operating profitability} = \frac{\text{gross profit - sgaa - interest expense}}{\text{book equity + minority interest}}`}</BlockMath>
                    <BlockMath>{String.raw`= \frac{${items.grossProfit != null ? items.grossProfit : '?'}_\text{${items.grossProfitDate != null ? items.grossProfitDate : ''}}-${items.sellingGeneralAndAdministration != null ? items.sellingGeneralAndAdministration : '?'}_\text{${items.sellingGeneralAndAdministrationDate != null ? items.sellingGeneralAndAdministrationDate : ''}}-${items.interestExpense != null ? items.interestExpense : 0}_\text{${items.interestExpenseDate != null ? items.interestExpenseDate : ''}}}{${items.bookEquity != null ? items.bookEquity : '?'}+${items.minorityInterest != null ? items.minorityInterest : 0}_\text{${items.minorityInterestDate != null ? items.minorityInterestDate : ''}}} = ${items.frenchOperatingProfitability != null ? items.frenchOperatingProfitability : '?'}`}</BlockMath>
                    <Typography variant="body1" gutterBottom>
                        &rarr; Score : {items.frenchOperatingProfitabilityRank != null ? <RenderValueProfitabilityRank value={items.frenchOperatingProfitabilityRank} /> : <DoNotDisturbIcon />}
                    </Typography>
                    <Typography variant="h4" component="div" gutterBottom>
                        Ray Ball Operating Profitability
                    </Typography>
                    <BlockMath>{String.raw`\text{Operating profitability} = \frac{\text{gross profit - sgaa}}{\text{total asset}} = \frac{${items.grossProfit != null ? items.grossProfit : '?'}_\text{${items.grossProfitDate != null ? items.grossProfitDate : ''}}-${items.sellingGeneralAndAdministration != null ? items.sellingGeneralAndAdministration : '?'}_\text{${items.sellingGeneralAndAdministrationDate != null ? items.sellingGeneralAndAdministrationDate : ''}}}{${items.totalAssets != null ? items.totalAssets : '?'}_\text{${items.totalAssetsDate != null ? items.totalAssetsDate : ''}}} = ${items.ballOperatingProfitability != null ? items.ballOperatingProfitability : '?'}`}</BlockMath>
                    <Typography variant="body1" gutterBottom>
                        &rarr; Score : {items.ballOperatingProfitabilityRank != null ? <RenderValueProfitabilityRank value={items.ballOperatingProfitabilityRank} /> : <DoNotDisturbIcon />}
                    </Typography>

                    <Typography variant="h4" component="div" gutterBottom>{t('questions')}</Typography>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('peapmeDefinition')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t('peapmeDefinitionDetails')}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('pbComputation')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t('pbComputationDetails')}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('valueScoreComputation')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t('valueScoreComputationDetails1')}</Typography>
                            <Typography>{t('valueScoreComputationDetails2')}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('grossProfitabilityComputation')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t('grossProfitabilityComputationDetails')}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('grossProfitabilityScoreComputation')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t('grossProfitabilityScoreComputationDetails1')}</Typography>
                            <Typography>{t('grossProfitabilityScoreComputationDetails2')}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('operatingProfitabilityComputation')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t('operatingProfitabilityComputationDetails1')}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('operatingProfitabilityScoreComputation')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t('operatingProfitabilityScoreComputationDetails1')}</Typography>
                            <Typography>{t('operatingProfitabilityScoreComputationDetails2')}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('assetGrowthComputation')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t('assetGrowthComputationDetails')}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('investmentScoreComputation')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t('investmentScoreComputationDetails1')}</Typography>
                            <Typography>{t('investmentScoreComputationDetails2')}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('momentumScoreComputation')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{t('momentumScoreComputationDetails1')}</Typography>
                            <Typography>{t('momentumScoreComputationDetails2')}</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <div style={{ height: '50px' }}></div>
                </Grid>
            </div>
        );
    }


}

export default Stock;